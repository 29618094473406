
require("./../../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import React from 'react';
import { GetServerSideProps } from 'next';

import logger from 'utils/logger';
import chunkArray from 'utils/chunkArray';
import executeBatchedGraphQLRequests from 'utils/executeBatchedGraphQLRequests';
import * as API from 'api/queries/index';
import { EnvUtil } from 'common/util.bs';
import {
  ContentBlockName,
  ContentLayout,
  ExtraProps,
  parseBasicPageContent,
  parseSeoMetadata,
} from '../common/basicContent';
import { modifyBlocks } from '../api/queries/modifyBlocks';
import { make as SearchBarSection } from '../components/pages/index/SearchBarSection.bs';
import { getPageContent, getSkuPresentationList } from 'api/graphql/CmsData.bs';
import { Inventory } from 'models/sku.model';
import { parse as parseSkuList } from '../api/graphql/SkuPresentation/Gql__SkuPresentation__Decoder__List.bs';
import LogoSchemaMarkup from 'components/pages/index/LogoSchemaMarkup';
import { NO_CACHE, PRODUCT_SSR_PAGE_CACHE } from 'common/constants';
import { getTokenOptServer } from 'auth/user/AuthUtils.bs';
import { SkuPresentationListQuery } from 'api/graphql/generated-bff';

const BASE_ROUTE = '/';

type PageProps = {
  layerEnabled: boolean;
  content: string; // JSON string value
  inventory: Pick<Inventory, 'skus'>;
  skuList: string; // JSON string value
};

const Page = ({ layerEnabled, content, inventory, skuList }: PageProps) => {
  const skuDict = parseSkuList(skuList).map(sku => sku.codename);

  const extraProps: ExtraProps = {
    [ContentBlockName.SkuCarouselBlock]: {
      props: { inventory, skuDict },
    },
  };

  const inventoryWithoutHiddenCards = {
    ...inventory,
    skus: inventory.skus.filter(sku => !sku.hideFromAllClassicCategories),
  };

  return (
    <>
      {parseSeoMetadata(content)}
      <LogoSchemaMarkup />
      <SearchBarSection inventory={inventoryWithoutHiddenCards} />
      {parseBasicPageContent(content, ContentLayout.Classic, extraProps, { layerEnabled })}
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async context => {
  const cookies = context.req.headers.cookie;
  const isPreview = context.query?.preview !== undefined;
  const language = EnvUtil.getCmsLanguage();
  const config = await API.webConfig();
  const inventoryAPIErrorMessage = 'Could not retrieve inventory data';
  const getInventoryData = async () => {
    try {
      return await API.inventory(getTokenOptServer(cookies));
    } catch (error) {
      logger.error(
        {
          err: error,
          source: 'Inventory API',
          journey: 'Homepage',
        },
        `error: ${inventoryAPIErrorMessage}`
      );

      throw new Error(inventoryAPIErrorMessage);
    }
  };

  try {
    const inventory: Inventory = await getInventoryData();
    const content = await getPageContent(language, isPreview, BASE_ROUTE);
    const codenames = inventory.skus.filter(sku => sku.cmsId !== '').map(sku => sku.cmsId);

    const chunkedCodenames = chunkArray(codenames, 50);
    const skuListRequests = chunkedCodenames.map(
      async codenamesChunk => await getSkuPresentationList(language, isPreview, codenamesChunk)
    );
    const skuListResponses = await executeBatchedGraphQLRequests<SkuPresentationListQuery>(skuListRequests, 50);

    const skuList = JSON.stringify(skuListResponses.flatMap(list => list));
    const modifiedContent = await modifyBlocks(JSON.stringify(content.content), isPreview, language, true);

    const props = {
      layerEnabled: config.featureLayerBuyingWeb,
      skuList: skuList || [],
      content: modifiedContent,
      inventory,
    };

    if (!isPreview) {
      context.res.setHeader('Cache-Control', PRODUCT_SSR_PAGE_CACHE);
    }

    return { props };
  } catch (error) {
    if (error.message !== inventoryAPIErrorMessage) {
      logger.error(
        {
          err: error,
          journey: 'Homepage',
        },
        'Failed to fetch homepage content'
      );
    }

    context.res.setHeader('location', '/error/');
    context.res.setHeader('Cache-Control', NO_CACHE);
    context.res.statusCode = 500;
    context.res.end();
  }
};
export default Page;
