import Script from 'next/script';

import { useLayoutData } from 'contexts/LayoutDataContext';

const LogoSchemaMarkup: React.FC = () => {
  const layoutData = useLayoutData();

  const logoMarkup = {
    '@context': 'https://schema.org/',
    '@type': 'Organization',
    logo: layoutData?.headerLogo?.classicLogo?.url,
    url: process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL,
  };

  const jsonLd = JSON.stringify(logoMarkup);

  return <Script async id="logo-schema" type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonLd }} />;
};

export default LogoSchemaMarkup;
