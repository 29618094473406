// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as Models from "../../../models/Models.bs.js";
import * as Segment from "../../../bindings/Segment.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as IndexStyles from "./IndexStyles.bs.js";
import * as Router from "next/router";
import * as LayoutDataContext from "../../../utils/LayoutDataContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SearchBar$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/SearchBar/SearchBar.bs.js";
import * as SearchBar__ListItem$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/SearchBar/SearchBar__ListItem.bs.js";
import * as SearchBar__ListItemText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/SearchBar/SearchBar__ListItemText.bs.js";

var container = Curry._1(BsCss.style, {
      hd: BsCss.marginTop(BsCss.px(12)),
      tl: {
        hd: BsCss.marginBottom(BsCss.px(12)),
        tl: {
          hd: BsCss.important(BsCss.zIndex(20)),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  container: container
};

function containsSearchTerm(text, searchTerm) {
  return text.toLowerCase().includes(searchTerm.toLowerCase());
}

function filterList(skus, searchStr) {
  return Belt_Array.slice(Belt_Array.keepMap(skus, (function (item) {
                    if (containsSearchTerm(item.name, searchStr)) {
                      return item;
                    }
                    
                  })), 0, 5);
}

function mapToListItem(sku) {
  var card = Models.Card.skuToCard(sku);
  return {
          name: card.name,
          id: card.uid,
          slug: card.slug
        };
}

var SearchBarHelpers = {
  containsSearchTerm: containsSearchTerm,
  filterList: filterList,
  mapToListItem: mapToListItem
};

function SearchBarSection(props) {
  var inventory = props.inventory;
  var router = Router.useRouter();
  var state = LayoutDataContext.useContext(undefined);
  var placeholder = React.useMemo((function () {
          var defaultPlaceholder = "Search retailers...";
          if (state !== undefined && state.searchBarPlaceholder.length > 0) {
            return state.searchBarPlaceholder;
          } else {
            return defaultPlaceholder;
          }
        }), [Belt_Option.map(state, (function (c) {
                return c.searchBarPlaceholder;
              }))]);
  var skus = React.useRef([]);
  var match = React.useState(function () {
        return "";
      });
  var setSearch = match[1];
  var search = match[0];
  var match$1 = React.useState(function () {
        return [];
      });
  var setList = match$1[1];
  var list = match$1[0];
  React.useEffect((function () {
          skus.current = Belt_Option.mapWithDefault(inventory, [], (function (i) {
                  return i.skus;
                }));
        }), [inventory]);
  var onEnter = React.useCallback((function (str) {
          router.push("/store/?search=" + encodeURIComponent(str));
        }), [
        router,
        list
      ]);
  var onChange = React.useCallback((function (searchStr) {
          Curry._1(setSearch, (function (param) {
                  return searchStr.trim();
                }));
          if (searchStr.trim() === "") {
            return Curry._1(setList, (function (param) {
                          return [];
                        }));
          }
          var filteredCards = filterList(skus.current, searchStr);
          Curry._1(setList, (function (param) {
                  return filteredCards;
                }));
        }), []);
  var onReset = React.useCallback((function (param) {
          Curry._1(setSearch, (function (param) {
                  return "";
                }));
          Curry._1(setList, (function (param) {
                  return [];
                }));
          Segment.customElementInteraction({
                element: "clear_search",
                journey: "classic_product_search"
              });
        }), []);
  var listItems = Belt_Array.map(list, (function (sku) {
          var item = mapToListItem(sku);
          var products = Belt_Array.keep(skus.current, (function (sku) {
                  return sku.name === item.name;
                }));
          return JsxRuntime.jsx(SearchBar__ListItem$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(Link, {
                            href: "/store/" + item.slug,
                            prefetch: false,
                            children: JsxRuntime.jsx("a", {
                                  children: JsxRuntime.jsx(SearchBar__ListItemText$PrezzeeRibbon.make, {
                                        text: item.name
                                      }),
                                  onClick: (function (param) {
                                      Segment.productsSearched({
                                            query: search,
                                            search_count: 1,
                                            products: Belt_Array.mapWithIndex(products, Segment.cardToProductWithIndex),
                                            search_location: "home",
                                            suggested_search: item.name
                                          });
                                    })
                                })
                          })
                    }, item.id);
        }));
  return JsxRuntime.jsx("section", {
              children: JsxRuntime.jsx(SearchBar$PrezzeeRibbon.make, {
                    onChange: onChange,
                    onEnter: onEnter,
                    onReset: onReset,
                    className: container,
                    placeholder: placeholder,
                    children: listItems
                  }),
              className: Cn.make([
                    IndexStyles.fullWidthSection,
                    "search-bar"
                  ])
            });
}

var Link$1;

var make = SearchBarSection;

export {
  Link$1 as Link,
  Styles ,
  SearchBarHelpers ,
  make ,
}
/* container Not a pure module */
